<script>
  import { onDestroy, onMount } from 'svelte'
  import { fly } from 'svelte/transition'
  import Parallax from 'parallax-js/src/parallax'

  export let images

  let root
  let parallax

  let timer
  let stage = 0

  const stagedImages = images.filter((img) => Object.keys(img).includes('stage'))
  const maxStage = stagedImages.reduce((res, img) => {
    if (img.stage > res) {
      return img.stage
    }
    return res
  }, 0)

  images = images.map((img) => {
    return {
      ...img,
      staged: Object.keys(img).includes('stage')
    }
  })

  onMount(() => {
    parallax = new Parallax(root, {
      scalarX: 7,
      scalarY: 7
    })

    if (stagedImages.length) {
      timer = setInterval(() => {
        stage = stage + 1

        if (stage > maxStage) {
          stage = 0
        }
      }, 3000)
    }
  })

  onDestroy(() => {
    if (parallax) {
      parallax.destroy()
    }

    clearInterval(timer)
  })

  let depthStep = 1 / images.length
</script>

<div class="root" bind:this={root} data-friction-x="0.1" data-friction-y="0.1" data-scalar-x="25" data-scalar-y="15">
  {#each images as img, i (img.url)}
    <div
      data-depth="{img.depth || (i+1) * depthStep}"
      class:staged={img.staged}
      class:active={stage === img.stage}
      class:stagedFly={img.staged && !img.transition}
      class:stagedFlip={img.staged && img.transition === 'flip'}
      class:stagedFade={img.staged && img.transition === 'fade'}
    >
      <img src="{img.url}" alt="" />
    </div>
  {/each}
</div>

<style>.root{position:relative;overflow:hidden}.root div{max-width:106%;margin:-3%}.root div img{width:100%;display:block}.stagedFly{opacity:0;-webkit-transition:opacity .25s ease;transition:opacity .25s ease}.stagedFly img{-webkit-transform:translateX(100px);transform:translateX(100px);-webkit-transition:-webkit-transform .25s ease;transition:-webkit-transform .25s ease;transition:transform .25s ease;transition:transform .25s ease,-webkit-transform .25s ease}.stagedFly.active{opacity:1}.stagedFade{opacity:0;-webkit-transition:opacity .25s ease;transition:opacity .25s ease}.stagedFade.active{opacity:1}.stagedFly.active img{-webkit-transform:translateX(0);transform:translateX(0)}.stagedFlip img{-webkit-transform-origin:center;transform-origin:center;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-transform:rotateY(180deg);transform:rotateY(180deg);-webkit-transition:-webkit-transform 1s ease;transition:-webkit-transform 1s ease;transition:transform 1s ease;transition:transform 1s ease,-webkit-transform 1s ease}.stagedFlip.active img{-webkit-transform:rotateY(0deg);transform:rotateY(0deg)}</style>
