<script>
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import { Player, FileProvider } from '@vime-js/standard'
  import VideoResponsive from './VideoResponsive.svelte'

  let player
  export let src

  let playing
  let isVideoReady = false

  onMount(() => {
    const stores = player.getStore()
    playing = stores.playing
    stores.isVideoReady.subscribe((val) => {
      isVideoReady = val
    })
  })

  const click = () => {
    const stores = player.getStore()

    stores.paused.set(!player.paused)
  }
</script>

<div class="root" class:prepare={!isVideoReady}>
  <Player
    bind:this={player}
    providers={[FileProvider]}
    src={src}
    isControlsActive={false}
    isControlsEnabled={false}
    autoplay={true}
    loop={true}
  />
  <div on:click={click} class="play-btn" class:playing={$playing}></div>
</div>

<style>.root{position:relative;-webkit-transition:opacity .65s ease;transition:opacity .65s ease;opacity:1}.root.prepare{opacity:0}.play-btn{position:absolute;top:0;left:0;-webkit-transition:opacity .15s ease;transition:opacity .15s ease;width:100%;height:100%;background-image:url(/images/play.png);background-size:120px 120px;background-position:50%;background-repeat:no-repeat}.play-btn.playing{opacity:0}</style>
