<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'
  import debounce from 'lodash/debounce'
  import { portalAction } from '../../actions'
  import { fly, fade } from 'svelte/transition'
  import { expoOut, expoIn } from 'svelte/easing'
  import { swipedown } from '../../actions/swipedown'

  onMount(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('fix-scroll')
    }
  })

  onDestroy(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('fix-scroll')
    }
  })

  const dispatch = createEventDispatcher()

  function close () {
    dispatch('closeClick')
  }

  let top = 0
  let left = 0

  function backdropMove (e) {
    left = e.clientX - 22
    top = e.clientY
  }

  let rootRef
  let enableSwipeDown

  onMount(() => {
    enableSwipeDown = rootRef.scrollTop === 0
  })

  const onscroll = debounce((e) => {
    enableSwipeDown = e.target.scrollTop === 0
  }, 50)

  let isTransition = false

  const transitionStart = () => {
    isTransition = true
  }

  const transitionEnd = () => {
    setTimeout(() => {
      isTransition = false
    }, 100)
  }
</script>

<div bind:this={rootRef} on:scroll={onscroll} class="root" class:trans={isTransition} use:portalAction>
  <div transition:fade={{ duration: 100 }} class="backdrop"></div>
  <div class="wrap">
<!--  on:mousemove={backdropMove} style={`--left: ${left}px; --top: ${top}px`}  -->
    <div on:click={close} class="spacer"></div>
    <div use:swipedown={{ onSwiped: close, enabled: enableSwipeDown }}
         class="content"
         on:introstart={transitionStart}
         on:outrostart={transitionStart}
         on:introend={transitionEnd}
         on:outroend={transitionEnd}
         in:fly={{ y: 300, duration: 550, easing: expoOut }}
         out:fly={{ y: 300, duration: 150, easing: expoIn }}
    >
      <slot></slot>
    </div>
  </div>
</div>

<style>.root{position:fixed;bottom:0;left:0;width:100%;height:100%;z-index:4;overflow:auto;overscroll-behavior-y:contain}.root.trans{overflow:hidden!important}.backdrop{position:absolute;top:0;left:0;width:100%;height:100%;z-index:1;background:rgba(0,0,0,.5)}.content{background:var(--body-background);z-index:2;width:100%}.content,.wrap{position:relative}.wrap{display:-webkit-box;display:-ms-flexbox;display:flex;min-height:100vh;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;z-index:1}.spacer{--left:0;--top:0;-webkit-box-flex:1;-ms-flex:1;flex:1;min-height:80px}@media (max-width:480px){.content::before{content:"";position:absolute;background:#fff;height:4px;border-radius:2px;width:50px;left:50%;margin-left:-25px;top:15px;opacity:.5}}</style>
