<script>
  import { onMount, onDestroy } from 'svelte'
  import { fade, fly } from 'svelte/transition'
  import { expoOut } from 'svelte/easing'
  import VideoResponsive from './VideoResponsive.svelte'

  export let images = []

  let timer

  let index = 0
  let slide
  let x = 100

  $: slide = images[index]

  const gotoSlide = (i) => {
    if (i < 0) {
      index = images.length - 1
    } else if (i >= images.length) {
      index = 0
    } else {
      index = i
    }
  }

  onMount(() => {
    images.forEach((m) => {
      const img = new Image()
      img.src = m
    })
  })
</script>

<div class="root">
  <div class="slide">
    <VideoResponsive ratio="1:1">
    {#each [slide] as sl (sl)}
      <img in:fly={{ x }} out:fly={{ x: -x }} src={sl} alt="" />
    {/each}
    </VideoResponsive>
  </div>
  {#if images.length > 1}
    <div class="pager">
      <span on:click={() => { gotoSlide(index-1) }} class="sliderArrow"></span> <span class="pages">{index+1} / {images.length}</span> <span on:click={() => { gotoSlide(index+1) }} class="sliderArrow right"></span>
    </div>
  {/if}
</div>

<style>.root{position:relative}.pager{text-align:center;position:absolute;bottom:30px;width:100%}.pages{vertical-align:middle;margin:0 15px;font-size:13px}.slide{line-height:0;overflow:hidden}.slide img{width:100%}.sliderArrow{width:30px;height:30px;display:inline-block;vertical-align:middle;background:url(/images/slider_arrow_dark.svg);cursor:pointer}.sliderArrow.right{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.image{max-width:100%}@media (prefers-color-scheme:dark){.sliderArrow{background:url(/images/slider_arrow_light.svg)}}</style>
