<script>
  import { onMount, onDestroy } from 'svelte'
  import { fly } from 'svelte/transition'
  import { expoOut } from 'svelte/easing'

  import VanillaTilt from 'vanilla-tilt'

  let root
  let timer

  export let images
  export let type = 'phone'

  let activeImg = 0

  onMount(() => {
    VanillaTilt.init(root, {
      reverse: true,
      max: 5
    })

    timer = setInterval(() => {
      let nextActiveImg = activeImg + 1
      if (nextActiveImg > images.length-1) {
        nextActiveImg = 0
      }

      activeImg = nextActiveImg
    }, 5000)
  })

  onDestroy(() => {
    try {
      root.vanillaTilt.destroy()
      clearInterval(timer)
    } catch (e) {}
  })

</script>

<div class={`wrap ${type}`}>
  <div bind:this={root}
       class={`root ${type}`}
       data-tilt-full-page-listening
  >
    <img alt="" class="frame" src={`/images/${type}.png`} />
    <div class="images">
      {#each images as img, i}
        <img alt="" class:active={i <= activeImg} src={img} />
      {/each}
    </div>
  </div>
</div>

<style>.wrap{overflow:hidden}.wrap.web{padding:0 20px}.root{overflow:hidden;height:500px;position:relative;margin:30px auto 0}.root.phone{max-width:320px}.frame{position:absolute;top:0;left:0;z-index:2}.phone .frame{max-width:320px}.images{position:absolute;z-index:1;overflow:hidden}.phone .images{left:23px;top:20px;width:275px;height:600px}.images img{width:100%;-webkit-transform:translateY(100px);transform:translateY(100px);opacity:0;position:absolute;top:0;left:0;-webkit-transition:opacity .2s ease-out,-webkit-transform .2s ease-out;transition:opacity .2s ease-out,-webkit-transform .2s ease-out;transition:transform .2s ease-out,opacity .2s ease-out;transition:transform .2s ease-out,opacity .2s ease-out,-webkit-transform .2s ease-out}.root.web,.web .frame{max-width:454px}.web .frame{width:100%}.web .images{left:3.744493%;right:3.744493%;top:3.744493%;bottom:0}.images img.active{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}</style>
