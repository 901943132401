<script>
  export let ratio
</script>

<style>.video-responsive{display:block;overflow:hidden;padding:0;position:relative;width:100%}.video-responsive::before{content:"";display:block;padding-bottom:56.25%}.video-responsive .wrap,.video-responsive :global(embed),.video-responsive :global(iframe),.video-responsive :global(img),.video-responsive :global(object){border:0;bottom:0;height:100%;left:0;position:absolute;right:0;top:0;width:100%}video.video-responsive{height:auto;max-width:100%}video.video-responsive::before{content:none}.video-responsive-4-3::before{padding-bottom:75%}.video-responsive-1-1::before{padding-bottom:100%}</style>

<div
  class={`video-responsive ${ratio ? `video-responsive-${ratio.replace(':', '-')}` : ''}`}
>
  <div class="wrap"><slot></slot></div>
</div>
