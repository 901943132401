<script>
  import get from 'lodash/get'
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'

  import VideoResponsive from './Media/VideoResponsive.svelte'
  import VideoPlayer from './Media/VideoPlayer.svelte'
  import DeviceMock from './Media/DeviceMock.svelte'
  import Gallery from './Media/Gallery.svelte'
  import Parallax from './Media/Parallax.svelte'

  export let data

  const dispatch = createEventDispatcher()

  const close = () => {
    dispatch('closeClick')
  }

  let ctaText = get(data, 'cta.text')
  let ctaButtonLabel = get(data, 'cta.button')

  // Для живого счётчика. неуклюже, но пока хз, как это сделать более элегантно
  let textEl
  let counterTimer

  let mediaBackgroundColor = null

  if (data.media && data.media.type === 'parallax' && !data.media.background) {
    data.media.background = '#fff'
  }

  onMount(() => {
    const counter = textEl.querySelector('[data-counter]')

    if (counter) {
      let num = parseInt(counter.innerText)
      counter.innerText = num.toLocaleString('ru')

      counterTimer = setInterval(() => {
        if (Math.random() < 0.7) {
          num++
          counter.innerText = num.toLocaleString('ru')
        }
      }, 1500)
    }
  })

  onDestroy(() => {
    clearInterval(counterTimer)
  })
</script>

<style>.root{display:-webkit-box;display:-ms-flexbox;display:flex;min-height:auto}@media (hover:hover),screen and (min-width:0\0){.root{min-height:0!important}}.root > *{-webkit-box-flex:1;-ms-flex:1;flex:1}.content{overflow-y:auto;padding:35px;font-size:24px;line-height:1.5}.content:not(:last-child){max-height:-webkit-fit-content;max-height:-moz-fit-content;max-height:fit-content}.content::-webkit-scrollbar{display:none}.content :global(h1){font-size:1.25em;margin:0 0 30px;line-height:normal}.content :global(h1 sup){font-size:.43333em;vertical-align:super}.content :global(.inline-emoji){height:1.5em;vertical-align:bottom;display:inline-block;position:relative}.content :global(.inline-emoji img){height:2em;margin-top:-.3em}.media{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.media > div{position:sticky;top:0}.image{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;-o-object-position:left center;object-position:left center}.cta{font-size:13px;font-weight:600;margin-top:80px}.cta-text{margin-bottom:15px;color:var(--muted-text-color)}.bg-media{width:100%;height:100%}.bg-media.clients{min-height:calc(100vh - 104px)}@media(max-width:1200px){.root{min-height:auto}}@media (max-width:900px){.root{display:block}.content:not(:last-child){max-height:none}}@media (max-width:480px){.content{padding:30px 30px 35px;font-size:20px;line-height:1.25}.content :global(h1){margin-bottom:40px}.root{display:block;font-size:.666666rem}.content :global(.inline-emoji){height:1.1em}.bg-media.clients{min-height:calc(100vh - 140px)}}</style>

<div class="root" style={`min-height: ${data?.style === 'fix-height' ? 'calc(100vh - 95px)' : null}`} on:click|preventDefault={() => data.media?.className === 'clients' ? close() : null}>
  <div class="content" style="flex-grow: {data.media?.className === 'clients' ? '0' : '1'}; padding: {data.media?.className === 'clients' && '0'}">
    <div bind:this={textEl}>
      {@html data.html}
    </div>

    <div class="cta">
      <div class="cta-text">
        {#if (ctaText)}
          {@html ctaText}
        {/if}
      </div>

      <!-- <a class="mail-button" href="mailto:box@kr.digital?subject=Обсудить проект">{ctaButtonLabel || 'Обсудить проект'}</a> -->
    </div>
  </div>

  {#if data.media}
    <div class="media" style={`background: ${data.media.background ? data.media.background: null}`}>
      {#if data.media.type === 'video'}
        <VideoResponsive>
          <VideoPlayer src={data.media.url} />
        </VideoResponsive>
      {:else if data.media.type === 'phone-mock'}
        <DeviceMock type="phone" images={data.media.images} />
      {:else if data.media.type === 'web-mock'}
        <DeviceMock type="web" images={data.media.images} />
      {:else if data.media.type === 'image'}
        <img class="image" alt="" src={data.media.url} />
      {:else if data.media.type === 'gallery'}
        <Gallery images={data.media.images} />
      {:else if data.media.type === 'parallax'}
        <Parallax images={data.media.images} />
      {:else if data.media.type === 'background'}
        <div class="{`bg-media ${data.media.className ? data.media.className : ''}`}"></div>
      {/if}
    </div>
  {/if}
</div>
